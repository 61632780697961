import './App.css';

function Root() {
  return (
    <div>
        <p>My portfolio</p>
        <a href={"rapid_multiplication"}>rapid multiplication</a>
    </div>
  );
}

export default Root;
